<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <el-card class="add-card">
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="120"
        :button="button"
        class="basic-data-add-form"
        @submit="onSubmit"
        @reset="onCancel"
      >
        <template #inputCodeTipIcon="{ item }">
          <el-tooltip
            popper-class="workshop_code_tip-icon"
            effect="dark"
            :content="getToolTipContent(item)"
            placement="right"
          >
            <i class="el-icon-tip el-icon-question" />
          </el-tooltip>
        </template>
      </m-form>
    </el-card>
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import { getEditBaseFormData } from '../data'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'

export default {
  name: 'WarehouseAreaAdd',
  components: {
    MForm,
    HeaderPath
  },
  mixins: [addMixins, commonMixins],
  props: {
    // 新增/修改模式
    mode: {
      type: String,
      default: 'add'
    },
    // 编辑或者查看详情 行信息
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      workshopList: []
    }
  },
  computed: {
    headerPaths() {
      return this.mode === 'add'
        ? [this.$t('lang.gles.base.warehouseAreaAdd')]
        : [this.$t('lang.gles.base.warehouseAreaEdit')]
    },
    baseFormData() {
      return getEditBaseFormData(this, { ...this.formModel })
    }
  },
  created() {
    this.initfilterMin()
    this.formModel = { ...this.rowDetail }
    if (this.mode === 'add') {
      this.QueryingSwitches()
      this.getSerialBizCode('warehouseAreaSerialNum', 'warehouseAreaCode')
    }
    this.initSelectList()
  },
  methods: {
    /*
      过滤table list 的在库时长单位
    */
    initfilterMin() {
      Object.keys(this.rowDetail).forEach((item) => {
        if (
          Object.prototype.hasOwnProperty.call(this.rowDetail, item) &&
          this.rowDetail[item]
        ) {
          if (item === 'holdTime') {
            this.rowDetail[item] = this.rowDetail[item].replace(
              /[a-zA-Z]/g,
              ''
            )
          }
        }
      })
    },
    /**
     * 初始化下拉选项
     */
    initSelectList() {
      this.getFactoryList()
      if (this.mode === 'edit') {
        this.getWorkshopList({ factoryId: this.formModel.factoryId })
        this.getWarehouseList({ factoryId: this.formModel.factoryId })
      }
    },
    getToolTipContent(item) {
      if (item.name === 'warehouseAreaId') {
        return '自动生成2位字母+6位数字的唯一编码；可输入50个字符以内的字母、数字、-或上述组合'
      }
      return '【所属车间】和【所属仓库】二选其一；与生产车间相关时选择车间，与仓储相关时选择仓库'
    },
    /**
     * 所属工厂 change
     */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      const type = val ? 'cascade' : null
      this.resetWorkshop(this.formModel, type)
      this.resetWarehouse(this.formModel, type)
    },
    onSubmit() {
      // 先校验
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        const params = { ...this.formModel }
        // 所属车间 所属仓库不能同时为空
        if (!params.workshopId && !params.warehouseId) {
          this.$message.error(this.$t('所属车间和所属仓库不能同时为空'))
          return
        }
        this.mode === 'add'
          ? await this.insert(params)
          : await this.update(params)
      })
    },
    /**
     * 新增
     */
    async insert(params) {
      Object.keys(params).forEach((item, index) => {
        // 过滤字段，改val 值
        if (item === 'goodsHoldFlagStatus') {
          delete params[item]
        } else if (item === 'goodsHoldFlag') {
          params[item] = params[item] ? 1 : 0
          if (params[item] === 0) {
            params.holdTime = ''
          }
        }
      })
      const { code } = await this.$httpService(
        this.$apiStore.base('insertWarehouseArea'),
        params
      )
      if (code !== 0) {
        return
      }
      this.onCancel()
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
    },
    /**
     * 编辑
     */
    async update(params) {
      Object.keys(params).forEach((item, index) => {
        // 过滤字段，改val 值
        if (item === 'goodsHoldFlagStatus') {
          delete params[item]
        } else if (item === 'goodsHoldFlag') {
          params[item] = params[item] ? 1 : 0
          if (params[item] === 0) {
            params.holdTime = ''
          }
        }
      })
      const { code } = await this.$httpService(
        this.$apiStore.base('updateWarehouseArea'),
        params
      )
      if (code !== 0) {
        return
      }
      this.onCancel()
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
    },
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'WarehouseAreaList'
      })
    },
    // 新增 系统开关 查询
    async QueryingSwitches() {
      const goodsHoldFlagStatus = 1 // 是否新增显示
      const goodsHoldFlag = true // 根据 swtichStatus 判断input 是否显示
      this.$set(this.formModel, 'goodsHoldFlagStatus', goodsHoldFlagStatus)
      this.$set(this.formModel, 'goodsHoldFlag', goodsHoldFlag)
      getEditBaseFormData(this, { ...this.formModel })
    }
  }
}
</script>
