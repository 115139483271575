import mapVerify from '@/utils/formValidate'

// 获取{{ $t('lang.gles.common.moreQuery') }}字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 库区编码
    {
      name: 'warehouseAreaCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.warehouseAreaCode'),
      clearable: true
    },
    // 库区名称
    {
      name: 'warehouseAreaName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.warehouseAreaName'),
      clearable: true
    },
    // 所属工厂
    {
      name: 'factoryId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      data: that.factoryList,
      mchange: that.handleFactoryChange
    },
    // 所属车间
    {
      name: 'workshopId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWorksop'),
      data: that.workshopList,
      disabled: !!formModel.warehouseId
    },
    // 所属仓库
    {
      name: 'warehouseId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWarehouse'),
      data: that.warehouseList,
      disabled: !!formModel.workshopId
    }
  ]
  moreQueryData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 库区编码
    {
      prop: 'warehouseAreaCode',
      label: that.$t('lang.gles.base.warehouseAreaCode'),
      isShow: true,
      showOverflowTooltip: true,
      sortable: true
    },
    // 库区名称
    {
      prop: 'warehouseAreaName',
      label: that.$t('lang.gles.base.warehouseAreaName'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 所属工厂
    {
      prop: 'factoryName',
      label: that.$t('lang.gles.base.belongingFactory'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 所属车间
    {
      prop: 'workshopName',
      label: `${that.$t('lang.gles.base.belongingWorksop')}`,
      isShow: true,
      showOverflowTooltip: true
    },
    // 所属仓库
    {
      prop: 'warehouseName',
      label: `${that.$t('lang.gles.base.belongingWarehouse')}`,
      isShow: true,
      showOverflowTooltip: true
    },
    // 在库时长
    {
      prop: 'holdTime',
      label: `${that.$t('lang.gles.base.warehouseArea.storageTimeInLibrary')}`,
      isShow: true,
      showOverflowTooltip: true
    },
    // 库区描述
    {
      prop: 'description',
      label: `${that.$t('lang.gles.base.warehouse​AreaDesc')}`,
      isShow: true,
      showOverflowTooltip: true
    }
  ]

  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 库区编码
    {
      name: 'warehouseAreaCode',
      value: row.warehouseAreaCode || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.warehouseAreaCode'),
      clearable: true,
      rules: mapVerify(['required', 'inputCode']),
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon'
    },
    // 库区名称
    {
      name: 'warehouseAreaName',
      value: row.warehouseAreaName || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.warehouseAreaName'),
      clearable: true,
      placeholder: '200个字符以内，支持任意字符',
      rules: mapVerify(['required', 'inputLen200'])
    },
    // 所属工厂
    {
      name: 'factoryId',
      value: row.factoryId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      data: that.factoryList,
      clearable: true,
      filterable: true,
      rules: mapVerify(['required']),
      mchange: that.handleFactoryChange
    },
    // 所属车间
    {
      name: 'workshopId',
      value: row.workshopId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWorksop'),
      data: that.workshopList,
      clearable: true,
      filterable: true,
      appendSlotName: 'inputCodeTipIcon',
      disabled: !!that?.formModel?.warehouseId,
      class: 'tip-icon'
    },
    // 所属仓库
    {
      name: 'warehouseId',
      value: row.warehouseId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWarehouse'),
      data: that.warehouseList,
      clearable: true,
      filterable: true,
      appendSlotName: 'inputCodeTipIcon',
      disabled: !!that?.formModel?.workshopId,
      class: 'tip-icon'
    },
    // 库区描述
    {
      name: 'description',
      value: row.description || '',
      span: 24,
      component: 'elInput',
      type: 'textarea',
      rows: 6,
      adaptionw: true,
      showWordLimit: false,
      clearable: true,
      label: that.$t('lang.gles.base.warehouse​AreaDesc'),
      rules: mapVerify(['inputLen500'])
    },
    // 在库时长状态
    {
      name: 'goodsHoldFlag',
      value: row.goodsHoldFlag ? 1 : 0,
      span: 12,
      activeColor: '#13ce66',
      component: 'elSwitch',
      adaptionw: true,
      showWordLimit: false,
      clearable: true,
      goodsHoldFlagStatus: row.goodsHoldFlagStatus,
      label: that.$t('lang.gles.base.warehouseArea.storageTimeInLibrary'),
      condition(formModel) {
        return formModel.goodsHoldFlagStatus
      }
    },
    // 在库时长 分钟
    {
      name: 'holdTime',
      value: row.holdTime || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      placeholder: that.$t('lang.gles.base.warehouseAreaHoldTimeNotNull'),
      clearable: false,
      label: that.$t('lang.gles.base.warehouseArea.storageTimeInLibraryGreaterThan'),
      rules: mapVerify(['required', 'min', 'max']),
      condition(formModel) {
        return !!formModel.goodsHoldFlag && formModel.goodsHoldFlagStatus
      }
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return formData
}
