<template>
  <div class="container-type">
    <component
      :is="currentCom"
      :mode="mode"
      :row-detail.sync="row"
      @updateCom="updateCom"
      @update:row-detail="rowDetail = $event"
    />
  </div>
</template>
<script>
import WarehouseAreaList from './components/list.vue'
import WarehouseAreaAdd from './components/add.vue'
export default {
  components: {
    WarehouseAreaList,
    WarehouseAreaAdd
  },
  data() {
    return {
      currentCom: 'WarehouseAreaList',
      mode: '',
      row: {}
    }
  },
  methods: {
    updateCom({ currentCom, mode, row = {}}) {
      this.currentCom = currentCom
      this.mode = mode
      row.holdTime = row.holdTimeNewVal?.replace(/[a-zA-Z]/g, '')
      this.row = row
      console.log(this.currentCom, this.mode, this.row)
    }
  }
}
</script>
<style lang="scss" scoped></style>
